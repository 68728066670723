import React from "react";

export default class Spinner extends React.Component {
  render() {
    return (
      <section className="loading-spinner">
        <div className="lds-grid">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </section>
    );
  }
}
